import React, { useEffect } from 'react';

const StaticMenuPage = () => {
  useEffect(() => {
    window.location.href = `${process.env.PUBLIC_URL}/assets/menu/akorno_menu.pdf`;
  }, []);

  return null;
};

export default StaticMenuPage;
